// Webpack Imports
import 'bootstrap';

new WOW().init();

( function ( $ ) {
	'use strict';

	// Focus input if Searchform is empty
	$( '.search-form' ).on( 'submit', function ( e ) {
		var search = $( this ).find( 'input' );
		if ( search.val().length < 1 ) {
			e.preventDefault();
			search.trigger( 'focus' );
		}
	} );

	// Menu behaviour
	var Menu = (function() {
		var burger    = document.querySelector('.burger');
		var menu      = document.querySelector('.menu');
		var menuList  = document.querySelector('.menu__list');
		var brand     = document.querySelector('.menu__brand');
		var menuItems = document.querySelectorAll('.menu__item');

		var active = false;

		var toggleMenu = function() {
			if (!active) {
				menu.classList.add('menu--active');
				menuList.classList.add('menu__list--active');
				brand.classList.add('menu__brand--active');
				burger.classList.add('burger--close');
				for (var i = 0, ii = menuItems.length; i < ii; i++) {
					menuItems[i].classList.add('menu__item--active');
				}

				active = true;
			} else {
				menu.classList.remove('menu--active');
				menuList.classList.remove('menu__list--active');
				brand.classList.remove('menu__brand--active');
				burger.classList.remove('burger--close');
				for (var i = 0, ii = menuItems.length; i < ii; i++) {
					menuItems[i].classList.remove('menu__item--active');
				}

				active = false;
			}
		};

		var bindActions = function() {
			burger.addEventListener('click', toggleMenu, false);
		};

		var init = function() {
			bindActions();
		};

		return {
			init: init
		};

	}());
	Menu.init();

	// Header behaviour
	$(function () {
		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) {
				$('.header').addClass('scroll_effect');
				$('.header .navbar-brand img').css('height','80px');
			}
			if ($(this).scrollTop() < 100) {
				$('.header').removeClass('scroll_effect');
				$('.header .navbar-brand img').css('height','unset');
			}
			if ($(this).scrollTop() > 500) {
				$('.back-to-top').addClass('visible');
			}
			if ($(this).scrollTop() < 500) {
				$('.back-to-top').removeClass('visible');
			}
		})
	});

}( jQuery ) );
